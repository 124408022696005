(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/participants/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/lb-ui/participants/assets/javascripts/constants.js');
"use strict";


const DescriptionSize = {
  Short: 'short',
  Medium: 'medium',
  Full: 'full'
};
const getTeamName = (participant, nameSize) => {
  let name;
  switch (nameSize) {
    case DescriptionSize.Short:
      name = participant === null || participant === void 0 ? void 0 : participant.shortName;
      break;
    case DescriptionSize.Full:
      name = participant === null || participant === void 0 ? void 0 : participant.name;
      break;
    case DescriptionSize.Medium:
    default:
      name = participant === null || participant === void 0 ? void 0 : participant.mediumName;
      break;
  }
  return name || (participant === null || participant === void 0 ? void 0 : participant.mediumName) || (participant === null || participant === void 0 ? void 0 : participant.shortName) || (participant === null || participant === void 0 ? void 0 : participant.name);
};
setGlobal('svs.components.lbUi.participants.constants', {
  DescriptionSize,
  getTeamName
});

 })(window);