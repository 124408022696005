(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/participants/assets/javascripts/participants-names.js') >= 0) return;  svs.modules.push('/components/lb-ui/participants/assets/javascripts/participants-names.js');
"use strict";


const {
  useMemo
} = React;
const {
  SmartAbbr,
  constants: {
    getTeamName,
    DescriptionSize
  }
} = svs.components.lbUi.participants;
const {
  clx
} = svs.components.lbUtils;
const getTeamNameAndTitle = (participant, nameSize) => {
  const name = getTeamName(participant, nameSize);
  const title = (participant === null || participant === void 0 ? void 0 : participant.name) || name;
  return [name, title];
};
const ParticipantName = _ref => {
  let {
    className,
    participant,
    nameSize = DescriptionSize.Medium
  } = _ref;
  const [teamName, teamTitle] = useMemo(() => getTeamNameAndTitle(participant, nameSize), [participant, nameSize]);
  return React.createElement("span", {
    className: clx('participant', className)
  }, React.createElement(SmartAbbr, {
    title: teamTitle
  }, teamName));
};
const ParticipantsNames = _ref2 => {
  let {
    homeParticipant,
    awayParticipant,
    nameSize = DescriptionSize.Medium
  } = _ref2;
  return React.createElement(React.Fragment, null, React.createElement(ParticipantName, {
    className: "home-participant",
    nameSize: nameSize,
    participant: homeParticipant
  }), React.createElement("span", {
    className: "participant-split"
  }, " - "), React.createElement(ParticipantName, {
    className: "away-participant",
    nameSize: nameSize,
    participant: awayParticipant
  }));
};
setGlobal('svs.components.lbUi.participants', {
  ParticipantName,
  ParticipantsNames
});

 })(window);