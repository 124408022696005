(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/participants/assets/javascripts/smart-abbr.js') >= 0) return;  svs.modules.push('/components/lb-ui/participants/assets/javascripts/smart-abbr.js');
"use strict";

const SmartAbbr = _ref => {
  let {
    children,
    title
  } = _ref;
  if (!children && !title) {
    return null;
  }
  if (children === title || !title) {
    return React.createElement("span", {
      translate: "no"
    }, children);
  }
  return React.createElement("abbr", {
    title: title,
    translate: "no"
  }, children);
};
setGlobal('svs.components.lbUi.participants.SmartAbbr', SmartAbbr);

 })(window);